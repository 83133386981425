import React from 'react'
import { Box, Link as MuiLink, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import Image from 'next/image'
import theme, { COLOR } from 'src/styles/theme'
import { rem } from 'src/common/utils/css'
import { Link_Target } from 'src/common/constants'
import Download from 'src/components/icons/Download'

const useStyles = makeStyles()(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'nowrap',
    backgroundColor: COLOR.BAKER_BEACH_WHITE,
    padding: `${rem(24)} ${rem(17)} ${rem(24)} ${rem(16)}`,
    gap: rem(8),
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      padding: `${rem(16)} ${rem(28)} ${rem(16)} ${rem(24)}`,
      gap: rem(20),
    },
    [theme.breakpoints.up('lg')]: {
      padding: `${rem(16)} ${rem(30)} ${rem(16)} ${rem(24)}`,
      gap: rem(20),
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: rem(8),
  },
  heading: {
    color: theme.palette.primary.dark,
    [theme.breakpoints.up('md')]: {
      fontWeight: 500,
    },
  },
  linksBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: rem(16),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      gap: rem(24),
    },
  },
  linkItem: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    gap: rem(8),
  },
  linkText: {
    color: theme.palette.primary.dark,
    textDecorationColor: theme.palette.primary.dark,
    marginTop: rem(-5),
  },
  animatedUnderline: {
    ...theme.typography.body.inlineLink,
    '&:after': {
      ...theme.typography.body.inlineLink['&:after'],
      bottom: rem(1.5),
    },
  },
}))

const AppPromo = (props) => {
  const { data } = props
  const { heading, links = [] } = data
  const { classes } = useStyles()

  return (
    <Box className={classes.wrapper}>
      <Box sx={{ minWidth: rem(62), height: rem(82), position: 'relative' }}>
        <Image
          src="/assets/smartphone.png"
          layout="fill"
          alt="smartphone image"
          title="Smartphone image"
          objectFit="contain"
        />
      </Box>
      <Box className={classes.container}>
        <Typography variant="h4" className={classes.heading}>
          {heading || 'Download Transit App to Track Shuttles in Real Time'}
        </Typography>
        <Box className={classes.linksBox}>
          {Array.isArray(links) &&
            links.map((link, key) => (
              <Box className={classes.linkItem} key={key}>
                <Download
                  color={theme.palette.primary.dark}
                  height={20}
                  width={20}
                />
                <MuiLink
                  className={`${classes.linkText} ${classes.animatedUnderline}`}
                  href={link?.url}
                  target={Link_Target[link?.target]}
                  rel="noopener"
                  underline="none"
                  data-ga-location="shuttle_map"
                >
                  {link?.title || 'App Store'}
                </MuiLink>
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  )
}

export default AppPromo
