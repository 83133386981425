import React from 'react'
import { Box, Link as MuiLink, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import Image from 'next/image'
import theme, { COLOR } from 'src/styles/theme'
import { rem } from 'src/common/utils/css'
import { Link_Target } from 'src/common/constants'
import Button from 'src/common/components/button/Button'
import ArrowRight from 'src/components/icons/ArrowRight'
import sanitize from 'src/common/utils/js/sanitize'
import isUrlExternal from 'src/common/utils/js/isUrlExternal'
import { TextButtonExternalLink } from 'src/common/components/externalSiteIcon'

const useStyles = makeStyles()(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: COLOR.LIGHT_BACKGROUND,
    padding: rem(24),
    gap: rem(8),
  },
  heading: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: rem(12),
  },
  title: {
    color: theme.palette.primary.dark,
  },
  description: {
    ...theme.typography.body.default,
    letterSpacing: 0,
    '& p:first-child': {
      marginBlockStart: 0,
    },
    '& p:last-child': {
      marginBlockEnd: 0,
    },
    '& a': {
      ...theme.typography.body.inlineLink,
      color: theme.palette.primary.dark,
    },
    '& br': {
      display: 'block',
      content: "''",
      marginTop: rem(21),
    },
  },
  linksBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: rem(8),
    marginTop: rem(11),
  },
  linkItem: {
    justifyContent: 'flex-start',
    padding: 0,
    maxWidth: 'max-content',
  },
  linkText: {
    ...theme.typography.body.inlineLink,
    color: theme.palette.primary.dark,
    textDecorationColor: theme.palette.primary.dark,
  },
}))

const MapCard = (props) => {
  const { data } = props
  const {
    icon: cardLogo,
    content_block_heading: title,
    content_block_body: description,
    content_block_cta_group: links,
  } = data
  const { classes } = useStyles()
  const gaTag = 'shuttle_map'
  return (
    <Box className={classes.wrapper}>
      <Box className={classes.heading}>
        {cardLogo && cardLogo.url && (
          <Box sx={{ width: rem(32), height: rem(32), position: 'relative' }}>
            <Image
              src={cardLogo.url}
              layout="fill"
              objectFit="contain"
              alt={cardLogo.alt || 'Map Icon'}
              title={cardLogo?.title || 'Map Icon'}
            />
          </Box>
        )}
        <Typography variant="h3" className={classes.title}>
          {title}
        </Typography>
      </Box>
      <Typography component="div" className={classes.description}>
        {sanitize(description, gaTag)}
      </Typography>
      {Array.isArray(links) && (
        <Box className={classes.linksBox}>
          {links.map(
            (link, index) =>
              link.content_block_cta.url && (
                <MuiLink
                  href={link.content_block_cta?.url}
                  target={Link_Target[link.content_block_cta?.target]}
                  aria-label={link.content_block_cta?.title}
                  title={link.content_block_cta?.title}
                  underline="none"
                  sx={{
                    width: 'max-content',
                  }}
                  data-ga-location="shuttle_map"
                  key={index}
                >
                  <Button
                    variant="text"
                    disableRipple
                    endIcon={
                      isUrlExternal(link.content_block_cta?.url) ? (
                        <TextButtonExternalLink />
                      ) : (
                        <ArrowRight />
                      )
                    }
                    className={classes.linkItem}
                    title={link.content_block_cta?.title}
                    tabIndex={-1}
                    sx={{
                      width: 'max-content',
                      '>span': {
                        marginRight: 'unset',
                      },
                    }}
                  >
                    {link.content_block_cta?.title}
                  </Button>
                </MuiLink>
              )
          )}
        </Box>
      )}
    </Box>
  )
}

export default MapCard
