import React, { useMemo, useEffect, useState } from 'react'
import { Box, Link as MuiLink, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import PropTypes from 'prop-types'
import Image from 'next/image'
import theme, { COLOR, SECONDARY_COLOR } from 'src/styles/theme'
import { rem } from 'src/common/utils/css'
import { Link_Target } from 'src/common/constants'
import AppPromo from 'src/components/shuttle-map/AppPromo'
import MapCard from 'src/components/shuttle-map/MapCard'
import ExternalLink from 'src/components/icons/ExternalLink'
import sanitize from 'src/common/utils/js/sanitize'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

const useStyles = makeStyles()((defaultTheme, props) => {
  const { colorIcon } = props
  return {
    wrapper: {
      backgroundColor: COLOR.NEAR_WHITE,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: `${rem(32)} ${rem(24)}`,
      gap: rem(24),
      [theme.breakpoints.up('md')]: {
        padding: `${rem(64)} ${rem(40)}`,
      },
      [theme.breakpoints.up('lg')]: {
        padding: `${rem(64)} ${rem(40)}`,
      },
      [theme.breakpoints.up('xl')]: {
        padding: `${rem(64)} ${rem(156)}`,
      },
    },
    heading: {
      display: 'flex',
      flexDirection: 'column',
      gap: rem(8),
      color: theme.palette.primary.dark,
      [theme.breakpoints.up('md')]: {
        fontWeight: 500,
      },
    },
    subHeading: {
      ...theme.typography.body.default,
      color: COLOR.DARK_GRAY,
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: rem(24),
      [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
      },
    },
    mapBox: {
      position: 'relative',
      aspectRatio: '4/3',
      lineHeight: 0,
      [theme.breakpoints.up('lg')]: {
        minWidth: '50vw',
      },
      [theme.breakpoints.up('xl')]: {
        minWidth: rem(648),
      },
    },
    contentBox: {
      display: 'flex',
      flexDirection: 'column',
      gap: rem(24),
      [theme.breakpoints.up('xl')]: {
        minWidth: rem(456),
      },
    },
    linksBox: {
      display: 'flex',
      flexDirection: 'row',
      gap: rem(16),
      [theme.breakpoints.up('md')]: {
        gap: rem(24),
      },
    },
    headingLinkBox: {
      display: 'flex',
      alignItems: 'center',
      gap: rem(3),
      textDecorationColor: colorIcon,
      [theme.breakpoints.up('md')]: {
        maxWidth: 'fit-content',
      },
      '&:hover': {
        textDecorationColor: theme.palette.secondary.dark,
      },
      '&:active': {
        textDecorationColor: SECONDARY_COLOR.DARK[60],
      },
    },
    linkText: {
      ...theme.typography.largeBody.default,
      color: colorIcon,
      textDecorationColor: colorIcon,
      letterSpacing: 0,
      flexGrow: 1,
      [theme.breakpoints.up('md')]: {
        flexGrow: 0,
      },
    },
  }
})

const ShuttleMap = (props) => {
  const { data } = props

  // guards
  if (!data || Object.keys(data).length === 0) {
    return null
  }

  const { shuttle_routes_maps, section_id } = data

  const {
    heading,
    sub_heading,
    map_link,
    display_shuttle_route,
    shuttle_route_link,
    single_location,
    static_map,
    content_block: mapCardsData = [],
    show_app_download_promo: showAppDownloadPromo,
    app_download_promo_heading,
    app_download_promo_ios,
    app_download_promo_google_play,
  } = shuttle_routes_maps

  const appPromoData = {
    heading: app_download_promo_heading,
    links: [app_download_promo_ios, app_download_promo_google_play],
  }

  const [showImage, setShowImage] = useState(true)

  const iconDefaultColor = useMemo(() => theme.palette.primary.dark, [])
  const iconHoverColor = useMemo(() => theme.palette.secondary.dark, [])
  const iconActiveColor = useMemo(() => SECONDARY_COLOR.DARK[60], [])

  const [colorIcon, setColorIcon] = useState(iconDefaultColor)
  const { classes } = useStyles({ colorIcon })

  useEffect(() => {
    setColorIcon(iconDefaultColor)
  }, [])

  const handleMouseEnter = (e) => {
    setColorIcon(iconHoverColor)
  }

  const handleMouseLeave = (e) => {
    setColorIcon(iconDefaultColor)
  }

  const handleMouseDown = () => {
    setColorIcon(iconActiveColor)
  }

  const handleMouseUp = () => {
    setColorIcon(iconHoverColor)
  }

  const mapUrl = display_shuttle_route
    ? shuttle_route_link
    : `https://maps.google.com/maps?q=${single_location.address}&z=${
        single_location.zoom || 15
      }&output=embed`

  const showMap = display_shuttle_route ? true : single_location.address !== ''

  return (
    <Box
      className={`module ${classes.wrapper}`}
      id={section_id}
      data-id="section"
      role="region"
      aria-label={heading}
    >
      <Box className={classes.container}>
        <Box className={classes.heading}>
          <Typography variant="h2" className={classes.title}>
            {heading}
          </Typography>
          <Typography className={classes.subHeading}>
            {sanitize(sub_heading)}
          </Typography>
          {map_link && map_link.url && map_link.title && (
            <MuiLink
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              className={classes.headingLinkBox}
              href={map_link.url}
              target={Link_Target[map_link?.target]}
              rel="noopener"
              underline="always"
              aria-label={map_link.title}
              data-ga-location="shuttle_map"
            >
              <Typography className={classes.linkText}>
                {map_link.title}
              </Typography>
              <Box sx={{ width: rem(44), height: rem(44), padding: rem(10) }}>
                <ExternalLink
                  color={colorIcon}
                  height={24}
                  width={24}
                  aria-hidden="true"
                />
              </Box>
            </MuiLink>
          )}
        </Box>
        <Box className={classes.contentContainer}>
          <Box>
            <Box className={classes.mapBox}>
              {showMap && (
                <LazyLoadComponent>
                  <iframe
                    src={mapUrl}
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    loading="lazy"
                    title={single_location?.address || 'Shuttle Route'}
                    referrerPolicy="no-referrer-when-downgrade"
                    onLoad={() => setShowImage(false)}
                    onLoadCapture={(event) => event.preventDefault()}
                    aria-label={single_location?.address || 'Shuttle Route'}
                  />
                </LazyLoadComponent>
              )}
              {showImage && (
                <Image
                  src={static_map.url}
                  alt={static_map.alt || 'Map Image'}
                  title={static_map?.title}
                  layout="fill"
                  objectFit="cover"
                />
              )}
            </Box>
          </Box>
          <Box className={classes.contentBox}>
            {mapCardsData.map((mapCardData, index) => (
              <MapCard data={mapCardData} key={index} />
            ))}
            {showAppDownloadPromo && <AppPromo data={appPromoData} />}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ShuttleMap

const linkPropType = PropTypes.shape({
  title: PropTypes.string,
  url: PropTypes.string,
  target: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
})

ShuttleMap.propTypes = {
  data: PropTypes.shape({
    section_id: PropTypes.string,
    shuttle_routes_maps: PropTypes.shape({
      heading: PropTypes.string.isRequired,
      sub_heading: PropTypes.string,
      map_link: linkPropType,
      display_shuttle_route: PropTypes.bool.isRequired,
      shuttle_route_link: PropTypes.string,
      single_location: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
        zoom: PropTypes.number,
        place_id: PropTypes.string,
      }),
      static_map: PropTypes.shape({
        url: PropTypes.string,
      }).isRequired,
      content_block: PropTypes.arrayOf(
        PropTypes.shape({
          icon: PropTypes.shape({
            url: PropTypes.string,
            alt: PropTypes.string,
          }),
          content_block_heading: PropTypes.string,
          content_block_body: PropTypes.string,
          content_block_cta_group: PropTypes.arrayOf(
            PropTypes.shape({
              content_block_cta: linkPropType,
            })
          ),
        })
      ).isRequired,
    }),
  }),
}
